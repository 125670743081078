module.exports = {
  siteTitle: "Echse-Net",
  shortSiteTitle: "Echse-Net",
  siteDescription: "Echse-Net",
  siteUrl: "https://echse-net.eu",

  defaultLanguage: "pl",
  supportedLanguages: [
    {
      id: "pl",
      label: "Polski"
    },
    {
      id: "en",
      label: "English"
    }
  ],

  authorName: "Damian Krasowski"
};
